<template>
  <div class="compax">
    <div class="ACE1Pro">
      <div class="title">ACE 1 Pro</div>
      <div class="smallTitle">Above mainstream performance</div>
      <div class="osDMS w">
        <div class="img">
          <img src="@/assets/ET 7页面配图/资源 12ET7产品介绍.png" alt="" />
        </div>
        <div class="img">
          <img src="@/assets/ET 7页面配图/资源 1@4x2.png" alt="" />
        </div>
      </div>
      <div></div>
    </div>
    <div class="ACE1">
      <div class="title">ACE 1</div>
      <div class="smallTitle">Extremely narrow bezels</div>
      <div class="osDMS w">
        <div class="img">
          <img src="@/assets/ET 7页面配图/资源 12ET7产品介绍.png" alt="" />
        </div>
        <div class="img">
          <img src="@/assets/ET 7页面配图/资源 1@4x2.png" alt="" />
        </div>
      </div>
      <div></div>
    </div>
    <div class="MK215">
      <div class="title">MK 215</div>
      <div class="smallTitle">Customizable design and features</div>
      <div class="osDMS w">
        <div class="img">
          <img src="@/assets/ET 7页面配图/资源 12ET7产品介绍.png" alt="" />
        </div>
        <div class="img">
          <img src="@/assets/ET 7页面配图/资源 1@4x2.png" alt="" />
        </div>
      </div>
      <div></div>
    </div>
    <div class="ET7">
      <div class="title">ET7</div>
      <div class="smallTitle">Smart AI Barcode Scale</div>
      <div class="osDMS w">
        <div class="img">
          <img src="@/assets/ET 7页面配图/资源 12ET7产品介绍.png" alt="" />
        </div>
        <div class="img">
          <img src="@/assets/ET 7页面配图/资源 1@4x2.png" alt="" />
        </div>
      </div>
      <div></div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.compax {
  .ACE1Pro {
    padding-top: 24px;
    height: 419px;
    background: url('../../../assets/官网首页配图/第一页轮播图/ACE 1 Pro.png')
      no-repeat center;
    background-size: cover;
    .title {
      line-height: 37.65px;
      color: rgba(0, 92, 255, 1);
      text-align: center;
      font-size: 26px;
      font-weight: 700;
    }
    .smallTitle {
      margin-top: 5px;
      text-align: center;
      font-size: 16px;
      line-height: 23.17px;
      font-weight: 500;
      color: #fff;
    }
    .osDMS {
      margin: 21.95px auto 0px;
      width: 241px;
      margin-top: 16px;
      display: flex;
      justify-content: space-between;
      .img {
        width: 114px;
        height: 22.14px;
        background-clip: padding-box;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .ACE1 {
    padding-top: 24px;
    height: 333px;
    background: url('../../../assets/官网首页配图/第一页轮播图/ACE1.png')
      no-repeat center;
    background-size: cover;
    .title {
      line-height: 37.65px;
      color: rgba(0, 92, 255, 1);
      text-align: center;
      font-size: 26px;
      font-weight: 700;
    }
    .smallTitle {
      margin-top: 5px;
      text-align: center;
      font-size: 16px;
      line-height: 23.17px;
      font-weight: 500;
      color: #fff;
    }
    .osDMS {
      margin: 21.95px auto 0px;
      width: 241px;
      margin-top: 16px;
      display: flex;
      justify-content: space-between;
      .img {
        width: 114px;
        height: 22.14px;
        background-clip: padding-box;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .MK215 {
    padding-top: 24px;
    height: 488px;
    background: url('../../../assets/官网首页配图/第一页轮播图/MK215.png')
      no-repeat center;
    background-size: cover;
    .title {
      line-height: 37.65px;
      color: rgba(0, 92, 255, 1);
      text-align: center;
      font-size: 26px;
      font-weight: 700;
    }
    .smallTitle {
      margin-top: 5px;
      text-align: center;
      font-size: 16px;
      line-height: 23.17px;
      font-weight: 500;
      color: #fff;
    }
    .osDMS {
      margin: 21.95px auto 0px;
      width: 241px;
      margin-top: 16px;
      display: flex;
      justify-content: space-between;
      .img {
        width: 114px;
        height: 22.14px;
        background-clip: padding-box;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .ET7 {
    padding-top: 24px;
    height: 488px;
    background: url('../../../assets/官网首页配图/第一页轮播图/ET7.png')
      no-repeat center;
    background-size: cover;
    .title {
      line-height: 37.65px;
      color: rgba(0, 92, 255, 1);
      text-align: center;
      font-size: 26px;
      font-weight: 700;
    }
    .smallTitle {
      margin-top: 5px;
      text-align: center;
      font-size: 16px;
      line-height: 23.17px;
      font-weight: 500;
      color: #fff;
    }
    .osDMS {
      margin: 21.95px auto 0px;
      width: 241px;
      margin-top: 16px;
      display: flex;
      justify-content: space-between;
      .img {
        width: 114px;
        height: 22.14px;
        background-clip: padding-box;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
